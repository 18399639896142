<template>
  <div>
    <div class="header-container">
      <div>
        <v-breadcrumbs>
          <v-breadcrumbs-item class="breadcrumbs-hover">
            <i
              class="fal fa-calendar"
              style="margin: 0 10px 0 0; font-size: 20px"
            ></i>
            <span @click="main()">{{ $t("employee_ot.title") }}</span>
          </v-breadcrumbs-item>
          <v-breadcrumbs-item
            class="breadcrumbs-hover"
            v-if="$route.name === 'employee_ot.request'"
          >
            <span v-show="true">/</span>
            <span>{{
              $t("employee_roster.request_change_work_sheet.title")
            }}</span>
          </v-breadcrumbs-item>
        </v-breadcrumbs>
      </div>
    </div>
    <div class="content">
      <router-view />
    </div>
  </div>
</template>

<script>
export default {
  methods: {
    main() {
      this.$router
        .push({
          name: "employee_ot_request.index",
        })
        .catch(() => {});
    },
  },
};
</script>

<style scoped lang="scss">
.breadcrumbs-hover {
  font-size: 18px;
}
.content {
  margin-top: 10px;
  padding-top: 30px;
  border-top: 1px solid rgb(95, 95, 95);
}
</style>
